// Hooks
import { defineStore } from "pinia";
import { computed, reactive, ref } from "vue";

// Types
import type { I_Fields } from "./successfulEvaluation.types";
import type { I_it4CreateContract_Return } from "@/hooks/requests/it4CreateContract/it4CreateContract.types";

import { useTIWStore } from "../tiw/tiw";

/**
 * * 🆂🆃🅾🆁🅴 [Successful Evaluation]
 * */
export const useSuccessfulEvaluationStore = defineStore(
    "successfulEvaluation",
    () => {
        const TIWStore = useTIWStore();

        // |-------|
        // | STATE |
        // |-------|

        /** Значения полей для fetch-отправки формы */
        const fields = reactive<I_Fields>({
            fio: "",
            inn: "",
            passportSeriesNumber: "",
            passportIssueDate: "",
            email: "",
            phone: "",
            personal: false,
            duty: false,
            // acceptContract: false,
        });

        const contractData = ref<null | I_it4CreateContract_Return["data"]>(
            null
        );

        // |----------|
        // | COMPUTED |
        // |----------|

        /** Выбраны ли все Select ручного ввода характеристик */
        const isAcceptedToNext = computed<boolean>(() => {
            // return Object.values(fields).every((el) => {
            //     return Boolean(el);
            // });

            let key: keyof I_Fields;
            for (key in fields) {
                if (Object.prototype.hasOwnProperty.call(fields, key)) {
                    if (!TIWStore.locationIsUA && ["passportSeriesNumber", "passportIssueDate"].includes(key)) continue;
                    if (key === "email") continue;
                    if (fields[key]) continue;
                    return false;
                }
            }
            return true;
        });

        const destructuredFio = computed(() => {
            if (fields.fio.length) {
                const rawFio = fields.fio.split(" ");
                return {
                    lastName: rawFio[0] || "",
                    firstName: rawFio[1] || "",
                    middleName: rawFio[2] || "",
                };
            } else {
                return {
                    firstName: "",
                    middleName: "",
                    lastName: "",
                };
            }
        });

        // |---------|
        // | METHODS |
        // |---------|

        /** Сбросить значения STORE на по-умолчанию */
        const restoreToDefault = () => {
            let property: keyof I_Fields;
            for (property in fields) {
                if (Object.prototype.hasOwnProperty.call(fields, property)) {
                    if (
                        property === "personal" ||
                        property === "duty"
                        // || property === "acceptContract"
                    ) {
                        fields[property] = false;
                        return;
                    }
                    fields[property] = "";
                }
            }
            contractData.value = null;
        };

        return {
            // * 🆂🆃🅾🆁🅴
            fields,
            contractData,

            // * 🅲🅾🅼🅿🆄🆃🅴🅳
            destructuredFio,
            isAcceptedToNext,

            // * 🅼🅴🆃🅷🅾🅳🆂
            restoreToDefault,
        };
    }
);
