// Hooks
import { defineStore } from "pinia";
import { computed, reactive, ref, watch } from "vue";
import apiGetModels from "@/hooks/requests/apiGetModels/apiGetModels";
import apiGetProducts from "@/hooks/requests/apiGetProducts/apiGetProducts";

// Types
import type {
    I_ManualCharacteristicsData,
    I_field_brand,
    I_field_category,
    I_field_model,
    I_field_other,
    I_DefaultCategoryValue,
} from "./chooseDevice.types";
import { I_apiGetDeps_Categories } from "@/hooks/requests/apiGetDeps/apiGetDeps.types";

/**
 * * 🆂🆃🅾🆁🅴 [Choose Device]
 * */
export const useChooseDeviceStore = defineStore("chooseDevice", () => {
    const DefaultCategoryValues: I_DefaultCategoryValue[] = [
        {
            brandName: "Apple",
            categoryName: "iPhone",
        },
        {
            brandName: "Samsung",
            categoryName: "Smartphone",
        },
    ];

    // |-------|
    // | STATE |
    // |-------|

    /** Значения поля IMEI для fetch-отправки формы */
    const field_imei = reactive<{
        value: string;
        savedValue: string;
    }>({
        value: "",
        savedValue: "",
    });

    /** Значения поля Brand для fetch-отправки формы */
    const field_brand = reactive<I_field_brand>({
        value: null,
        data: [],
    });

    /** Значения поля Category для fetch-отправки формы */
    const field_category = reactive<I_field_category>({
        value: null,
        data: [],
    });

    /** Значения поля Model для fetch-отправки формы */
    const field_model = reactive<I_field_model>({
        value: null,
        data: [],
    });

    /** Значения поля Other (Память / Модель / Год) для fetch-отправки формы */
    const field_other = reactive<I_field_other>({
        value: null,
        data: [],
    });

    /** Значения поля Color для fetch-отправки формы */
    const field_color = reactive<I_field_other>({
        value: null,
        data: [],
    });

    /** Данные полей для ручного ввода характеристик */
    const manualCharacteristicsData = reactive<I_ManualCharacteristicsData>({
        brand: [],
        brand_categories: [],
        category: [],
        model: [],
        products: [],
        other: [],
        color: [],
    });

    /** Изображение "Превью" выбранного устройства для следующего шага формы */
    const deviceImagePreview = ref<string | null>(null);

    const crazyInit = ref<boolean>(false);

    // |----------|
    // | COMPUTED |
    // |----------|
    /** Перманентный игнор каких-то продуктов */
    const permanentIgnore = computed<boolean>((): boolean => {
        // category "Mac"
        if (field_category.value?.category_id === 3) {
            return true;
        }

        return false;
    });

    /** Название выбраного бренда товара */
    const brandName = computed<string>((): string => {
        if (field_brand.value) {
            return field_brand.value.name;
        }

        return "";
    });

    /** Выбраны ли все Select ручного ввода характеристик */
    const isSelectedManualFiles = computed<boolean>(() => {
        return [
            field_brand,
            field_category,
            field_model,
            field_other,
            field_color,
        ].every((field) => Boolean(field.value));
    });

    /**
     * Цепочка характеристик устройства
     * @example ["Apple", "iPhone", "12 Pro Max", "256GB", "Graphite"]
     */
    const deviceChain = computed<string[]>(() => {
        return [
            field_brand.value?.name,
            field_category.value?.name,
            field_model.value?.name,
            field_other.value?.variable,
            field_color.value?.color,
        ].filter((item: string | undefined) => item);
    });

    // |---------|
    // | METHODS |
    // |---------|

    /** Загрузить Модели */
    const fetchModels = async () => {
        const modelsData = await apiGetModels({
            brand_id: field_brand.value.brand_id,
            category_id: field_category.value.category_id,
        });

        // console.log(modelsData.data);

        manualCharacteristicsData.model = modelsData.data;
        field_model.data = modelsData.data;
    };

    /** Загрузить Продукты */
    const fetchProducts = async () => {
        const productData = await apiGetProducts({
            model_id: field_model.value.model_id,
        });

        // console.log(productData.data);

        manualCharacteristicsData.other = productData.data;
        field_other.data = productData.data.filter(
            (value, index, self) =>
                index === self.findIndex((t) => t.variable === value.variable)
        );
    };

    /** Сбросить значения STORE на по-умолчанию */
    const restoreToDefault = () => {
        [field_category, field_model, field_other, field_color].forEach(
            (field) => {
                field.value = null;
                field.data = [];
            }
        );

        field_imei.value = "";
        field_imei.savedValue = "";
        field_brand.value = null;
        deviceImagePreview.value = null;
    };

    // |----------|
    // | WATCHERS |
    // |----------|

    // ЛОКАЛЬНО ВЫЧИСЛЯЕТСЯ ПОЛЕ "КАТЕГОРИЯ" НА ОСНОВАНИИ ПОЛЯ "БРЕНД"
    watch(field_brand, () => {
        if (crazyInit.value) return;

        if (field_brand.value) {
            const filteredBrandCategoriesByBrand =
                manualCharacteristicsData.brand_categories.filter(
                    (el) => el.brand_id === field_brand.value.brand_id
                );

            const filteredCategory = filteredBrandCategoriesByBrand
                .map((cat_brand) => {
                    const data = manualCharacteristicsData.category.filter(
                        (cat) => cat_brand.category_id === cat.category_id
                    );
                    return data[0];
                })
                .sort((a, b) => {
                    const nameA = a.name.toUpperCase();
                    const nameB = b.name.toUpperCase();

                    if (nameA === "IPHONE") return -1;
                    if (nameA < nameB) return -1;
                    if (nameA > nameB) return 1;

                    return 0;
                });

            // console.log(filteredCategory);

            field_category.data = filteredCategory;
            field_category.value = null;

            DefaultCategoryValues.forEach((DefaultCategoryValue: I_DefaultCategoryValue) => {
                if (DefaultCategoryValue.brandName === field_brand.value.name) {
                    const categoryDataFound = field_category.data.find((categoryData: I_apiGetDeps_Categories) => {
                        return categoryData.name === DefaultCategoryValue.categoryName;
                    });

                    if (categoryDataFound) {
                        field_category.value = categoryDataFound;
                    }
                }
            });

            if (field_category.data.length === 1) {
                field_category.value = field_category.data[0];
            }
        } else {
            field_category.value = null;
            field_category.data = [];
        }
    });

    // ВЫЧИСЛЯЕТСЯ ПОЛЕ "МОДЕЛЬ" НА ОСНОВАНИИ ПОЛЯ "БРЕНД" + "КАТЕГОРИЯ"
    watch(field_category, async () => {
        if (crazyInit.value) return;

        if (field_brand.value && field_category.value) {
            await fetchModels();
            field_model.value = null;
        } else {
            field_model.value = null;
            field_model.data = [];
        }
    });

    // ЛОКАЛЬНО ВЫЧИСЛЯЕТСЯ ПОЛЕ "ПАМЯТЬ / МОДЕЛЬ / ГОД" НА ОСНОВАНИИ ПОЛЯ "МОДЕЛЬ"
    watch(field_model, async () => {
        if (crazyInit.value) return;

        if (field_brand.value && field_category.value && field_model.value) {
            await fetchProducts();
            field_other.value = null;
        } else {
            field_other.value = null;
            field_other.data = [];
        }
    });

    // ЛОКАЛЬНО ВЫЧИСЛЯЕТСЯ ПОЛЕ "ЦВЕТ" НА ОСНОВАНИИ ПОЛЯ "ПАМЯТЬ / МОДЕЛЬ / ГОД"
    watch(field_other, () => {
        if (crazyInit.value) return;

        if (
            field_brand.value &&
            field_category.value &&
            field_model.value &&
            field_other.value
        ) {
            field_color.data = manualCharacteristicsData.other.filter(
                (value, index, self) =>
                    index ===
                    self.findIndex(
                        (t) =>
                            t.color === value.color &&
                            t.variable === field_other.value.variable
                    )
            );
            field_color.value = null;
        } else {
            field_color.value = null;
            field_color.data = [];
        }
    });

    return {
        // * 🆂🆃🅾🆁🅴
        field_imei,
        field_brand,
        field_category,
        field_model,
        field_other,
        field_color,
        manualCharacteristicsData,
        deviceImagePreview,
        crazyInit,

        // * 🅲🅾🅼🅿🆄🆃🅴🅳
        permanentIgnore,
        brandName,
        isSelectedManualFiles,
        deviceChain,

        // * 🅼🅴🆃🅷🅾🅳🆂
        restoreToDefault,
    };
});
