// Types
import type { I_it4GetLoadPhotoFields_FieldsIds } from "./it4GetLoadPhotoFields.types";
import type { I_DownloadPhotos_Field_Names } from "@/stores/downloadPhotos/downloadPhotos.types";

/** Адаптер имён полей ИТ4 для имен полей виджета */
export const FIELD_NAMES_ADAPTER: Record<
    I_it4GetLoadPhotoFields_FieldsIds,
    I_DownloadPhotos_Field_Names
> = {
    "Front. Switch on": "frontImei", // Лицевая сторона с включенным экраном (IMEI, светлая тема)
    "Front. Battery": "frontBattery", // Экран с информацией о состоянии батареи
    "Front. Switch on White": "frontBattery", // Лицевая сторона с включённым экраном (светлый фон)
    "Front. Model": "frontModel", // Модель устройства
    "Front. Capacity": "frontCapacity", // Встроенная память
    "Front": "front", // Лицевая сторона
    "Back": "back", // Задняя сторона
    "Left": "leftSide", // Левая боковая грань
    "Right": "rightSide", // Правая боковая грань
    "Top": "topSide", // Верхняя грань
    "Bottom": "bottomSide", // Нижняя грань
    "Back side. Top corner": "backSideTopCorner", // Задняя сторона (правый верхний угол)
    "Back side. Bottom corner": "backSideBottomCorner", // Задняя сторона (нижний левый угол)
    "Internal display. Screen On": "internalDisplayScreenOn", // Внутренний дисплей с включенным экраном (Модель , светлая тема)
    "Internal side. Screen off": "internalSideScreenOff", // Внутренняя сторона в развёрнутом виде (выключенный экран)
    "External display. Screen On": "externalDisplayScreenOn", // Внешний дисплей с включенным экраном
    "External side. Screen off": "externalSideScreenOff", // Внешняя сторона в развёрнутом виде (выключенный экран)
    "Back side. Left": "backSideLeft", // Задняя сторона в сложенном виде (сторона сгиба, левый угол)
    "Back side. bottom right": "backSideBottomRight", // Задняя сторона в развёрнутом виде (нижний правый угол)
    "External display. Model": "externalDisplayModel", // Внешний дисплей с включенным экраном (Модель , светлая тема)
    "Back side. Top": "backSideTop", // Задняя сторона в свёрнутом виде (сторона сгиба, верхний угол)
};
