// Libs
import axios from "../configRequest";

// Data
import { I_AXIOS } from "../configRequest.model";

// Types
import type {
    I_setDeviceStatus,
    I_setDeviceStatus_Return,
} from "./it4SetDeviceStatus.types.ts";

/**
 * Установить статус девайса в ИТ4 на ORDER_CREATED | ONLINE_CONTRACT_GENERATED
 * 18.1 документация
 * */
const it4SetPhotoSetStatus: I_setDeviceStatus = async ({
    deviceId,
    status,
}) => {
    const setPhotoSetData = await axios.post<
        I_setDeviceStatus_Return["data"]
    >(
        `${I_AXIOS.STAGE_API_HOST}/api/tin/v1/site/quest/agc-status`,
        {},
        {
            headers: {
                Accept: "application/json;charset=UTF-8",
                "Content-Type": "application/json",
            },
            params: {
                deviceId,
                status,
            },
        }
    );
    return setPhotoSetData.data;
};

export default it4SetPhotoSetStatus;
