import { BugTrackerSection, useBugTracker } from "@/helpers/bugTracker";

import it4CreateContract from "@/hooks/requests/it4CreateContract/it4CreateContract";
import type { I_it4CreateContract_Return } from "@/hooks/requests/it4CreateContract/it4CreateContract.types";

import it4SetDeviceStatus from "@/hooks/requests/it4SetDeviceStatus/it4SetDeviceStatus";

type CreateContractPayload = {
    token: string,
    deviceId: string,
    lang: string,
    firstName: string,
    lastName: string,
    middleName: string,
    mobile: string,
    doc: string,
    docNum: string,
    docIssueDate: string,
};

type SetDeviceStatusPayload = {
    deviceId: string,
    status: "ORDER_CREATED" | "ONLINE_CONTRACT_GENERATED",
};

export async function createContract(payload: CreateContractPayload): Promise<I_it4CreateContract_Return["data"] | null> {
    try {
        const ContractData = await it4CreateContract(payload);

        useBugTracker("createContract - it4CreateContract (response)", BugTrackerSection.INFO, {
            response: ContractData,
        });

        return ContractData;
    } catch (e) {
        useBugTracker("createContract - it4CreateContract", BugTrackerSection.ERROR, {
            exception: e.message,
            request: payload,
        });

        return null;
    }
}

export async function setDeviceStatus(payload: SetDeviceStatusPayload): Promise<void> {
    try {
        await it4SetDeviceStatus(payload);
    } catch (e) {
        useBugTracker("setDeviceStatus - it4SetDeviceStatus", BugTrackerSection.ERROR, {
            exception: e.message,
            request: payload,
        })
    }
}
