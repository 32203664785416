<template>
    <Modal class="tiw__step__signingWithoutSms">
        <template #header>
            <h2 class="tiw__step__signingWithoutSms__header">
                <AwardIcon />
                {{ useTranslate("tiw__signingWithoutSms__title") }}
            </h2>

            <div class="tiw__step__signingWithoutSms__text">
                {{ useTranslate("tiw__signingWithoutSms__subtitle") }}
            </div>

            <div v-if="false" class="tiw__step__signingWithoutSms__text">
                <div>
                    {{ useTranslate("tiw__signingWithoutSms__downloadContract__title") }}
                </div>
                <div class="tiw__step__signingWithoutSms__link">
                    <svg class="tiw__step__signingWithoutSms__link__icon" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14 10V12.6667C14 13.0203 13.8595 13.3594 13.6095 13.6095C13.3594 13.8595 13.0203 14 12.6667 14H3.33333C2.97971 14 2.64057 13.8595 2.39052 13.6095C2.14048 13.3594 2 13.0203 2 12.6667V10" stroke="#808D9A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M4.66602 6.66667L7.99935 10L11.3327 6.66667" stroke="#808D9A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M8 10V2" stroke="#808D9A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>

                    <a class="tiw__step__signingWithoutSms__link__text" href="#" target="_blank">
                        {{ useTranslate("tiw__signingWithoutSms__downloadContract") }}
                    </a>
                </div>
            </div>
        </template>

        <div class="tiw__step__signingWithoutSms__body">
            <Button
                type="button"
                :disabled="loadingToNext"
                :loading="loadingToNext"
                class="tiw__step__signingWithoutSms__btn-send"
                @click="onAddToCart"
            >
                <span>
                    {{ useTranslate("tiw__signingWithoutSms__addToCart") }}
                </span>
            </Button>
        </div>
    </Modal>
</template>

<script lang="ts">
export default {
    name: "SigningWithoutSms",
};
</script>

<script setup lang="ts">
/**
 * * 🅲🅾🅼🅿🅾🅽🅴🅽🆃 [SigningWithoutSms]
 */

// Components
import Modal from "@/components/modal/modal.vue";
import Button from "@/ui/button/button.vue";
// import Checkbox from "@/ui/checkbox/checkbox.vue";

// Icons
import AwardIcon from "@/ui/icons/award.vue";

// Hooks
import useStoreResetter from "@/hooks/useStoreResetter/useStoreResetter";
import useTranslate from "@/hooks/useTranslate/useTranslate";
import { useAnalyticsEventAddToCart } from "@/hooks/useAnalytics/useAnalytics";

// Store
import { ref } from "vue";
import { useTIWStore } from "@/stores/tiw/tiw";
import { useChooseDeviceStore } from "@/stores/chooseDevice/chooseDevice";
import { useHowYouRateDeviceStore } from "@/stores/howYouRateDevice/howYouRateDevice";
import { useSuccessfulEvaluationStore } from "@/stores/successfulEvaluation/successfulEvaluation";
import parseStringPrice from "@/helpers/parseStringPrice";

const TIWStore = useTIWStore();
const ChooseDeviceStore = useChooseDeviceStore();
const HowYouRateDeviceStore = useHowYouRateDeviceStore();
const SuccessfulEvaluationStore = useSuccessfulEvaluationStore();

const loadingToNext = ref<boolean>(false);

const onAddToCart = async () => {
    useAnalyticsEventAddToCart();

    const onCartData = {
        brand: ChooseDeviceStore.brandName,
        device_id: HowYouRateDeviceStore.registeringDeviceData.deviceId,
        device_imei: ChooseDeviceStore.field_imei.value,
        trade_up_model: TIWStore.INJECTED_GOOD.panelTitle,
        trade_up_price: String(parseStringPrice(TIWStore.INJECTED_GOOD.panelPrice)),
        model: ChooseDeviceStore.deviceChain.join(" "),
        modelData: {
            brand: ChooseDeviceStore.field_brand.value?.name || "",
            category: ChooseDeviceStore.field_category.value?.name || "",
            model: ChooseDeviceStore.field_model.value?.name || "",
            other: ChooseDeviceStore.field_other.value?.variable || "",
            color: ChooseDeviceStore.field_color.value?.color || "",
            code: ChooseDeviceStore.field_color.value?.code || "",
        },
        price: String(parseStringPrice(HowYouRateDeviceStore.calculatedDeviceData.panelCost)),
        trade_in_price:
            String(parseStringPrice(
                typeof HowYouRateDeviceStore.calculatedDeviceData
                    .panelTradeInSale === "string"
                    ? HowYouRateDeviceStore.calculatedDeviceData
                          .panelTradeInSale
                    : HowYouRateDeviceStore.calculatedDeviceData
                          .panelTradeInSale.tradeInSale
            )),
        top_up_price: HowYouRateDeviceStore.calculatedDeviceData
            .panelActionSale
            ? String(parseStringPrice(HowYouRateDeviceStore.calculatedDeviceData.panelActionSale.actionSale))
            : null,
        grade: HowYouRateDeviceStore.registeringDeviceData.grade,
        certificate: "",
        signedDocumentUrl: "",
        final_price: true,
        first_name: SuccessfulEvaluationStore.destructuredFio.firstName,
        last_name: SuccessfulEvaluationStore.destructuredFio.lastName,
        email: SuccessfulEvaluationStore.fields.email,
        phone: SuccessfulEvaluationStore.fields.phone,
        signingType: "WITHOUT_SMS",
    };

    TIWStore.INJECTED_TIW.current_api.onCart(onCartData);

    // useBugTracker("AddToCart - onCart (callback)", BugTrackerSection.INFO, {
    //     onCartData: onCartData,
    // })

    TIWStore.closeWidget();
    useStoreResetter();
};
</script>

<style lang="scss" src="./signingWithoutSms.scss"></style>
