<template>
    <Modal class="tiw__step__chooseSigningType">
        <template #header>
            <h2 class="tiw__step__chooseSigningType__header">
                <AwardIcon />
                {{ useTranslate("tiw__chooseSigningType__title") }}
            </h2>

            <div class="tiw__step__chooseSigningType__text">
                {{ useTranslate("tiw__chooseSigningType__text") }}
            </div>
        </template>

        <div class="tiw__step__chooseSigningType__body">
            <div class="tiw__rate-chooser tiw__step__chooseSigningType__chooser">
                <div v-if="showTrustmeKz" class="tiw__rate-chooser__element" @click="onClickSigningType('addToCart')">
                    <label class="tiw__rate-chooser__item">
                        <svg class="tiw__rate-chooser__figure" width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="64" height="64" fill="white" />
                            <path d="M48 55C48 57.209 46.209 59 44 59H20C17.791 59 16 57.209 16 55V9C16 6.791 17.791 5 20 5H44C46.209 5 48 6.791 48 9V55Z" stroke="#768798" stroke-width="2.4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M38 5V7C38 8.10457 37.1046 9 36 9H28C26.8954 9 26 8.10457 26 7V5" stroke="#768798" stroke-width="2.4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M37 54L27 54" stroke="#768798" stroke-width="2.4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M25.3333 30.4091L27.5556 32.6818M27.5556 30.4091L25.3333 32.6818M30.8889 30.4091L33.1111 32.6818M33.1111 30.4091L30.8889 32.6818M36.4444 30.4091L38.6667 32.6818M38.6667 30.4091L36.4444 32.6818M37.5556 27H26.4444C24.3489 27 23.3022 27 22.6511 27.6659C22 28.3318 22 29.4023 22 31.5455C22 33.6886 22 34.7591 22.6511 35.425C23.3022 36.0909 24.3489 36.0909 26.4444 36.0909H37.5556C39.6511 36.0909 40.6978 36.0909 41.3489 35.425C42 34.7591 42 33.6886 42 31.5455C42 29.4023 42 28.3318 41.3489 27.6659C40.6978 27 39.6511 27 37.5556 27Z" stroke="#6F5FD4" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <div class="tiw__rate-chooser__body">
                            <h4 class="tiw__rate-chooser__title">
                                {{ useTranslate("tiw__chooseSigningType__signingType__trustmeKz__title") }}
                            </h4>
                            <p class="tiw__rate-chooser__text">
                                {{ useTranslate("tiw__chooseSigningType__signingType__trustmeKz__description") }}
                            </p>
                        </div>
                    </label>
                </div>
                <div v-if="showScrive" class="tiw__rate-chooser__element" @click="onClickSigningType('addToCart')">
                    <label class="tiw__rate-chooser__item">
                        <svg class="tiw__rate-chooser__figure" width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="64" height="64" fill="white" />
                            <path d="M48 55C48 57.209 46.209 59 44 59H20C17.791 59 16 57.209 16 55V9C16 6.791 17.791 5 20 5H44C46.209 5 48 6.791 48 9V55Z" stroke="#768798" stroke-width="2.4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M38 5V7C38 8.10457 37.1046 9 36 9H28C26.8954 9 26 8.10457 26 7V5" stroke="#768798" stroke-width="2.4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M37 54L27 54" stroke="#768798" stroke-width="2.4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M25.3333 30.4091L27.5556 32.6818M27.5556 30.4091L25.3333 32.6818M30.8889 30.4091L33.1111 32.6818M33.1111 30.4091L30.8889 32.6818M36.4444 30.4091L38.6667 32.6818M38.6667 30.4091L36.4444 32.6818M37.5556 27H26.4444C24.3489 27 23.3022 27 22.6511 27.6659C22 28.3318 22 29.4023 22 31.5455C22 33.6886 22 34.7591 22.6511 35.425C23.3022 36.0909 24.3489 36.0909 26.4444 36.0909H37.5556C39.6511 36.0909 40.6978 36.0909 41.3489 35.425C42 34.7591 42 33.6886 42 31.5455C42 29.4023 42 28.3318 41.3489 27.6659C40.6978 27 39.6511 27 37.5556 27Z" stroke="#6F5FD4" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <div class="tiw__rate-chooser__body">
                            <h4 class="tiw__rate-chooser__title">
                                {{ useTranslate("tiw__chooseSigningType__signingType__trustmeKz__title") }}
                            </h4>
                            <p class="tiw__rate-chooser__text">
                                {{ useTranslate("tiw__chooseSigningType__signingType__trustmeKz__description") }}
                            </p>
                        </div>
                    </label>
                </div>
                <div v-if="showWithoutSms" class="tiw__rate-chooser__element" @click="onClickSigningType('signingWithoutSms')">
                    <label class="tiw__rate-chooser__item">
                        <svg class="tiw__rate-chooser__figure" width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="64" height="64" fill="white" />
                            <path d="M48 55C48 57.209 46.209 59 44 59H20C17.791 59 16 57.209 16 55V9C16 6.791 17.791 5 20 5H44C46.209 5 48 6.791 48 9V55Z" stroke="#768798" stroke-width="2.4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M38 5V7C38 8.10457 37.1046 9 36 9H28C26.8954 9 26 8.10457 26 7V5" stroke="#768798" stroke-width="2.4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M37 54L27 54" stroke="#768798" stroke-width="2.4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M35.214 25.9815L36.616 24.5805C36.9877 24.2088 37.4918 24 38.0175 24C38.5432 24 39.0473 24.2088 39.419 24.5805C39.7907 24.9522 39.9995 25.4564 39.9995 25.982C39.9995 26.5077 39.7907 27.0118 39.419 27.3835L38.018 28.7855M35.214 25.9815L26.98 34.2155C25.935 35.2615 25.412 35.7835 25.056 36.4205C24.7 37.0575 24.342 38.5605 24 39.9995C25.438 39.6575 26.942 39.2995 27.579 38.9435C28.216 38.5875 28.739 38.0645 29.784 37.0195L38.018 28.7855M35.214 25.9815L38.018 28.7855M31 39.9995H37" stroke="#6F5FD4" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <div class="tiw__rate-chooser__body">
                            <h4 class="tiw__rate-chooser__title">
                                {{ useTranslate("tiw__chooseSigningType__signingType__withoutSms__title") }}
                            </h4>
                            <p class="tiw__rate-chooser__text">
                                {{ useTranslate("tiw__chooseSigningType__signingType__withoutSms__description") }}
                            </p>
                        </div>
                    </label>
                </div>
            </div>

            <div class="tiw_group tiw_group-sb">
                <Button
                    type="button"
                    variant="skeleton"
                    class="tiw__step__chooseSigningType__btn-back"
                    :disabled="loadingToNext"
                    @click="onClickBack"
                >
                    <ArrowIcon />
                    <span>{{ useTranslate("tiw__back") }}</span>
                </Button>
            </div>
        </div>
    </Modal>
</template>

<script lang="ts">
export default {
    name: "ChooseSigningType",
};
</script>

<script setup lang="ts">
/**
 * * 🅲🅾🅼🅿🅾🅽🅴🅽🆃 [ChooseSigningType]
 */

// Types
import type { I_TIW } from "@/stores/tiw/tiw.types";

// Components
import Modal from "@/components/modal/modal.vue";

// Icons
import AwardIcon from "@/ui/icons/award.vue";

// Hooks
import { computed, ref } from "vue";
import useTranslate from "@/hooks/useTranslate/useTranslate";
import { createContract, setDeviceStatus } from "@/helpers/singingType";

// Store
import { useTIWStore } from "@/stores/tiw/tiw";
import { useHowYouRateDeviceStore } from "@/stores/howYouRateDevice/howYouRateDevice";
import { useSuccessfulEvaluationStore } from "@/stores/successfulEvaluation/successfulEvaluation";
import Button from "@/ui/button/button.vue";
import ArrowIcon from "@/ui/icons/arrow.vue";

const TIWStore = useTIWStore();
const HowYouRateDeviceStore = useHowYouRateDeviceStore();
const SuccessfulEvaluationStore = useSuccessfulEvaluationStore();

const showTrustmeKz = computed<boolean>(() => HowYouRateDeviceStore.signingTypes.includes("TRUSTME_KZ"));
const showWithoutSms = computed<boolean>(() => HowYouRateDeviceStore.signingTypes.includes("WITHOUT_SMS"));
const showScrive = computed<boolean>(() => HowYouRateDeviceStore.signingTypes.includes("SCRIVE"));

const loadingToNext = ref<boolean>(true);

const onClickSigningType = async (step: I_TIW["CURRENT_STEP"]) => {
    loadingToNext.value = false;

    if (String(step) === "addToCart") {
        SuccessfulEvaluationStore.contractData = await createContract({
            token: TIWStore.WIDGET_DATA.token,
            deviceId: HowYouRateDeviceStore.registeringDeviceData.deviceId,
            lang: TIWStore.standardizedLocale,
            firstName: SuccessfulEvaluationStore.destructuredFio.firstName,
            lastName: SuccessfulEvaluationStore.destructuredFio.lastName,
            middleName: SuccessfulEvaluationStore.destructuredFio.middleName,
            mobile: SuccessfulEvaluationStore.fields.phone,
            doc: SuccessfulEvaluationStore.fields.inn,
            docNum: SuccessfulEvaluationStore.fields.passportSeriesNumber,
            docIssueDate: SuccessfulEvaluationStore.fields.passportIssueDate,
        });
    }

    if (String(step) === "signingWithoutSms") {
        SuccessfulEvaluationStore.contractData = await createContract({
            token: TIWStore.WIDGET_DATA.token,
            deviceId: HowYouRateDeviceStore.registeringDeviceData.deviceId,
            lang: TIWStore.standardizedLocale,
            firstName: SuccessfulEvaluationStore.destructuredFio.firstName,
            lastName: SuccessfulEvaluationStore.destructuredFio.lastName,
            middleName: SuccessfulEvaluationStore.destructuredFio.middleName,
            mobile: SuccessfulEvaluationStore.fields.phone,
            doc: SuccessfulEvaluationStore.fields.inn,
            docNum: SuccessfulEvaluationStore.fields.passportSeriesNumber,
            docIssueDate: SuccessfulEvaluationStore.fields.passportIssueDate,
        });

        await setDeviceStatus({
            deviceId: HowYouRateDeviceStore.registeringDeviceData.deviceId,
            status: "ONLINE_CONTRACT_GENERATED",
        });
    }

    TIWStore.switchStep(step);
    loadingToNext.value = true;
};

const onClickBack = () => {
    TIWStore.switchStep("successfulEvaluation");
};
</script>

<style lang="scss" src="./chooseSigningType.scss"></style>
